import React, { useEffect, useState } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import Header from '../components/Header';
import Footer2Mobile from '../components/Footer2_Mobile';
import Footer2 from '../components/Footer2';
import useWindowSize from '../hooks/useWindowSize';
import SecondHeader from '../components/SecondHeader';
import ReactMarkdown from 'react-markdown';
import articleImage from "../assets/article.jpg";
import styled from 'styled-components';
import articlesData from '../data/articlesData.json'; // Ensure correct path

const Container = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 100vh;
`;

const MainContent = styled.div`
    flex: 1;
    padding: 20px;
`;

const GridContainer = styled.div`
    display: grid;
    align-items: start;
    grid-template-columns: 1fr;

    @media (max-width: 768px) {
        grid-template-columns: 1fr; /* Stack image and text vertically */
    }
`;

const ImageContainer = styled.div`
    position: relative;
    display: flex;
    justify-content: center; /* Center image horizontally */
    overflow: hidden; /* Hide any overflow from image scaling */
    max-height: 400px; /* Adjust this value as needed */
    margin-bottom: 20px; /* Optional: Space between image and content */
`;

const Image = styled.img`
    width: 100%;
    height: auto; /* Maintain aspect ratio */
    object-fit: cover; /* Cover the container while preserving aspect ratio */
    border-radius: 8px;

    @media (max-width: 768px) {
        height: 200px; /* Adjust height for smaller screens */
    }
`;

const TitleOverlay = styled.div`
    position: absolute;
    bottom: 0; /* Position at the bottom */
    left: 0; /* Position at the left */
    width: 100%; /* Take full width */
    height: 100%; /* Take full height */
    display: flex;
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    color: white;
    text-align: center;
    padding: 20px; /* Add padding */
    box-sizing: border-box; /* Ensure padding is included in the height and width */

    h1 {
        font-size: 32px; /* Default font size */
        @media (max-width: 768px) {
            font-size: 24px; /* Font size for mobile devices */
        }
    }

`;

const ArticleContent = styled.div`
    max-width: 800px;
    margin: 0 auto;
    padding: 10px; /* Add some padding around the content */
    font-size: 16px;
     @media (max-width: 768px) {
         font-size: 14px;
    }
`;

const PaginationButtons = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
`;

const Button = styled.button<{ disabled: boolean }>`
    background-color: ${({ disabled }) => (disabled ? '#e0e0e0' : 'hsl(0, 0%, 98%)')};
    color: ${({ disabled }) => (disabled ? '#a0a0a0' : '#000')};
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
    font-size: 18px;

    
    &:hover {
        background-color: ${({ disabled }) => (disabled ? '#e0e0e0' : 'hsl(0, 0%, 90%)')};
    }

    @media (max-width: 768px) {
         font-size: 16px;
    }
`;

const BlogDetail: React.FC = () => {
    const { width } = useWindowSize();
    const isMobile = width <= 768;
    const navigate = useNavigate();
    const { id } = useParams<{ id: string }>();
    const location = useLocation();
    const [article, setArticle] = useState<any>(location.state || null);
    const [currentIndex, setCurrentIndex] = useState<number>(-1);

    useEffect(() => {
        const articleId = Number(id);

        // Find the index of the article in the array
        const index = articlesData.findIndex((item: any) => Number(item.id) === articleId);
        setCurrentIndex(index);

        if (index !== -1) {
            setArticle(articlesData[index]);
        } else {
            console.error('Article not found');
            setArticle(null); // Handle article not found case
        }
    }, [id]); // Only depend on id

    useEffect(() => {
        // Ensure scroll to top when article changes
        window.scrollTo(0, 0);
    }, [article]); // Depend on article change

    const handlePrevious = () => {
        if (currentIndex > 0) {
            const previousArticle = articlesData[currentIndex - 1];
            navigate(`/news/${previousArticle.id}`);
        }
    };

    const handleNext = () => {
        if (currentIndex < articlesData.length - 1) {
            const nextArticle = articlesData[currentIndex + 1];
            navigate(`/news/${nextArticle.id}`);
        }
    };

    if (!article) return <p>Loading...</p>;

    return (
        <Container>
            <Header />
            <SecondHeader title={"News"} />
            <MainContent>
                <GridContainer>
                    <ImageContainer>
                        <Image src={articleImage} alt={article.title} />
                        <TitleOverlay>
                            <h1>{article.title}</h1>
                        </TitleOverlay>
                    </ImageContainer>
                    <ArticleContent>
                        <ReactMarkdown>{article.content}</ReactMarkdown>
                    </ArticleContent>
                </GridContainer>
                <PaginationButtons>
                    <Button
                        disabled={currentIndex <= 0}
                        onClick={handlePrevious}
                    >
                        &lt; Previous
                    </Button>
                    <Button
                        disabled={currentIndex >= articlesData.length - 1}
                        onClick={handleNext}
                    >
                        Next &gt;
                    </Button>
                </PaginationButtons>
            </MainContent>
            {isMobile ? <Footer2Mobile /> : <Footer2 />}
        </Container>
    );
};

export default BlogDetail;
