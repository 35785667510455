import React, { useState } from 'react';
import './css/AboutVideo.css';
import AboutWithTickAndBackgroundComponent from './AboutWithTickAndBackground';
import CustomButton from './CustomButton';
interface TextVideoProps {
  content: string;
  showButton: string;
  videoLink: string
}


const TextVideoGreyComponent: React.FC<TextVideoProps> = ({ content, showButton, videoLink }) => {

  const containerStyle: React.CSSProperties = {
    backgroundColor: "#f0f0f0"
  };

  
  const handleEmailClick = () => {
    window.location.href = "mailto:discover@whatslegal.ai?subject=Subject%20Here&body=Body%20text%20here";
  };
  return (
    <div>

      <div className="about-video-container" style={containerStyle} >
        <div className="about-video-about" >

          <AboutWithTickAndBackgroundComponent paragraphs={[content]} />
          {showButton === "true" ?? (
            <div className="button-container">
              <CustomButton onClick={handleEmailClick} text="Contact Us" />
            </div>
          )}
        </div>
        <div className="about-video-video">
          <iframe
            src={videoLink}
            frameBorder="0"
            allowFullScreen
            title="Video 2"
          ></iframe>
        </div>

      </div>

    </div>
  );
};

export default TextVideoGreyComponent;
