import React, { useState } from 'react';
import CheckIcon from '@mui/icons-material/Check';
import './css/WhyWhatsLegal.css'; 
import AboutWithTickComponent from './AboutWithTick';
import CustomButton from './CustomButton';
import AboutWithTickAndBackgroundComponent from './AboutWithTickAndBackground';

const WhyWhatsLegal: React.FC = () => {

  const [whiteLabelContent, setWhiteLabelContent] = useState<string>(`# Warum Whatslegal?
* **Anonym:** Bei WhatsLegal fragen Sie anonym – kein Login, keine E-Mail erforderlich.
* **Kostenlos:** WhatsLegal ist kostenlos für 10 Fragen pro Tag (10 weitere Fragen für 2€).
* **Vertrauensvoll:** WhatsLegal referenziert direkt auf deutsche oder EU Gesetze.
* **Einfach:** Mit WhatsLegal kann jede*r Gesetze verstehen – egal ob 8 oder 80 Jahre.
      `);
      
  const containerStyle: React.CSSProperties = {
    backgroundColor: "#f0f0f0"
  };

  
  return (
    <div className="about-video-container" style={containerStyle} >
    <div className="about-video-about" >

      <AboutWithTickAndBackgroundComponent paragraphs={[whiteLabelContent]} />
    </div>
    <div className="about-video-video">
      <iframe
        src="https://share.synthesia.io/embeds/videos/30eb92cf-8033-4b5e-99b7-0349b2c60eb1"
        frameBorder="0"
        allowFullScreen
        title="Video 2"
      ></iframe>
    </div>

  </div>

      );
}

export default WhyWhatsLegal;
