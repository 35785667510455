import React from 'react';
import './css/About.css';
import ReactMarkdown from 'react-markdown';
import style from './css/markdown-tick-styles.module.css';
interface AboutComponentProps {
    paragraphs: string[];
}

const AboutWithTickComponent: React.FC<AboutComponentProps> = ({ paragraphs }) => {
    return (
        <section className="about">
            <div className="about-content">
                {paragraphs.map((paragraph, index) => (
                     <ReactMarkdown className={style.reactMarkDown}>{paragraph}</ReactMarkdown>
                ))}
            </div>
        </section>
    );
};

export default AboutWithTickComponent;
