// src/App.tsx
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './screens/Home';
import Faqs from './screens/Faqs';
import About from './screens/About';
import ArticlePage from './screens/Articles';
import Imprint from './screens/Imprint';
import Privacy from './screens/Privacy';
import PolicyTerms from './screens/PolicyTerms';
import CookiePolicy from './screens/CookiePolicy';
import TermsAndConditions from './screens/TermsAndConditions';
import TipsAndTricks from './screens/TipsAndTricks';
import BlogDetail from './screens/BlogDetail';
import Whitelabel from './screens/Whitelabel';
import WhatsLegalChat from './screens/ChatBot';
const App: React.FC = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/whatslegalchat" element={<WhatsLegalChat />} />
        <Route path="/faqs" element={<Faqs />} />
        <Route path="/about" element={<About />} />
        <Route path="/news" element={<ArticlePage />} />
        <Route path="/news/:id" element={<BlogDetail />} /> 
        
          
        <Route path="/impressum" element={<Imprint />} />
        <Route path="/Datenschutz" element={<Privacy />} />
        <Route path="/nutzungsbedingungen" element={<PolicyTerms />} />
        <Route path="/cookie-richtlinien" element={<CookiePolicy />} />
        <Route path="/tips-tricks" element={<TipsAndTricks />} />
        <Route path="/agbs" element={<TermsAndConditions />} />
        <Route path="/whitelabel" element={<Whitelabel />} />
      </Routes>
    </Router>
  );
};

export default App;
