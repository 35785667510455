import React from 'react';
import './css/AboutVideo.css';
import AboutWithTickComponent from './AboutWithTick';
import CustomButton from './CustomButton';

interface TextVideoProps {
  content: string;
  showButton: string;
  videoLink: string
}

const TextVideo: React.FC<TextVideoProps> = ({ content, showButton, videoLink }) => {

  const handleEmailClick = () => {
    window.location.href = "mailto:discover@whatslegal.ai?subject=Subject%20Here&body=Body%20text%20here";
  };

  return (
    <div>
      <div className="about-video-container">
        <div className="about-video-about">
          <AboutWithTickComponent paragraphs={[content]} />
          {showButton === "true" && (
            <div className="button-container">
              <CustomButton onClick={handleEmailClick} text="Contact Us" />
            </div>
          )}
        </div>
        <div className="about-video-video">
          <iframe
            src={videoLink}
            frameBorder="0"
            allowFullScreen
            title="Video 2"
          ></iframe>
        </div>
      </div>
    </div>
  );
};

export default TextVideo;
