import React, { useState } from 'react';
import './css/FAQ.css';
import faqData from '../data/faqData.json'; // Adjust the path as needed

const FAQ: React.FC = () => {
  const [openIndex, setOpenIndex] = useState<number | null>(null);

  const handleToggle = (index: number, event: React.MouseEvent) => {
    event.preventDefault();
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <section className="faq">
      <div>
        {faqData.map((faq, index) => (
          <details key={index} open={openIndex === index}>
              <summary onClick={(e) => handleToggle(index, e)}>{faq.question}</summary>
      {faq.answer && (
        <p dangerouslySetInnerHTML={{ __html: faq.answer }}></p>
      )}
          </details>
        ))}
      </div>
    </section>
  );
};

export default FAQ;
