import React from 'react';
import './css/Hero.css';
import { useNavigate } from 'react-router-dom';

const Hero: React.FC = () => {

    const navigate = useNavigate();
    
    const handleClick = () => {
        navigate(`/whatslegalchat`);
    };

  return (
    <section className="hero">
      <div className="hero-content">
        <h1>Wir machen Gesetze verständlich. Für alle.</h1>
        <p>Mit unserem Chat und</p>
        <p>unserer gen. AI platform</p>
        <button className="hero-button" onClick={handleClick}>
            Chat ausprobieren
        </button>
      </div>
    </section>
  );
};

export default Hero;
