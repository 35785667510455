import React, { useEffect, useState } from 'react';
import Header from '../components/Header';
import Footer2Mobile from '../components/Footer2_Mobile';
import Footer2 from '../components/Footer2';
import useWindowSize from '../hooks/useWindowSize';
import SecondHeader from '../components/SecondHeader';
import TextVideo from '../components/TextVideo';

const Whitelabel: React.FC = () => {
  const { width } = useWindowSize();
  const isMobile = width <= 768;

  const containerStyle: React.CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  };

  const mainContentStyle: React.CSSProperties = {
    flex: '1',
  };


  const [content, setContent] = useState<string>(`# Mehr Leads mit White&nbsp;Label
* **Verbesserter Kundenservice** durch sekundenschnelle Ersteinschätzung
* **Längere Verweildauer** auf Ihrer Webseite
* **Verbessertes Google Ranking**
* **Direkte Verlinkung** auf Ihre Angebote 
* **Leadqualifizierung** durch Übertragung des Chats (*Zustimmung der Nutzer*innen vorausgesetzt)
      `);


  return (
    <div style={containerStyle}>
      <Header />
      <SecondHeader title="White Label" />
      <TextVideo content={content} showButton={"true"} videoLink='https://share.synthesia.io/embeds/videos/cc7f6069-58a4-4748-a24f-77f788cfc8f3' />
      {isMobile ? <Footer2Mobile /> : <Footer2 />}
    </div>
  );
};

export default Whitelabel;
