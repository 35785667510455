import React from 'react';
import './css/Blog.css';
import BlogPost from './Blogpost';
import ArticleCard from './ArticleCard';
import Newsletter from './Newsletter';
import DetailArticleCard from './DetailArticleCard';
import articlesData from '../data/articlesData.json'; 
const Blog: React.FC = () => {
  return (
    <main className="blog container">
      <div className="articlePageList">
      {articlesData.map((article, index) => (
          <DetailArticleCard
            key={index}
            id= {article.id}
            title={article.title}
            imageSrc={article.imageSrc}
            altText={article.altText}
            content={article.content}
          />
        ))}
      </div>
      {/* <div className="pagination">
        <button>&lt; Previous</button>
        <button>Next &gt;</button>
      </div> */}
    </main>
  );
};

export default Blog;
