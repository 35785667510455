import React, { useEffect, useState } from 'react';
import Header from '../components/Header';
import Footer2Mobile from '../components/Footer2_Mobile';
import Footer2 from '../components/Footer2';
import useWindowSize from '../hooks/useWindowSize';
import Stats from '../components/Stats';
import Team from '../components/Team';
import SecondHeader from '../components/SecondHeader';
import Iframe from '../components/Iframe';
import TextVideo from '../components/TextVideo';
import TextComponent from '../components/Text';
import TextVideoGreyComponent from '../components/TextVideoGreyComponent';

const About: React.FC = () => {
  const { width } = useWindowSize();
  const isMobile = width <= 768;

  const containerStyle: React.CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  };

  const mainContentStyle: React.CSSProperties = {
    flex: '1',
  };

  const [content, setContent] = useState<string>(`# Our Purpose:
Die Idee zu WhatsLegal entstand aus dem Wunsch, einen Beitrag zur Stärkung der Demokratie und Rechtsstaatlichkeit in Deutschland zu leisten. Wir stellten uns die Frage: Wie können wir mithilfe von Technologie den sperrigen Zugang zum Recht vereinfachen und den Inhalt verständlicher machen, damit alle Bürger*innen unsere Gesetze verstehen? Egal ob 8 Jahre oder 80 Jahre.

Aus der Idee ist eine gen. AI Plattform für rechtliches Wissensmanagement geworden und derzeit arbeiten wir an einer Pro- und Enterprise Version.
`);

const [featuresContent, setFeaturesContent] = useState<string>(`# Features:
  * Macht Bundes- und Landesgesetze für alle Bürger*innen leicht zugänglich und verständlich.
  * Bietet eine vertrauensvolle, anonyme Informationsquelle zur Selbsteinschätzung der eigenen rechtlichen Lage (keine Cookies, kein Tracking, keine Anmeldung, keine persönlichen Daten).
  * Unterstützt Laien (und Profis) beim Recherchieren in Gesetzen.
  * Befähigt (Legal Enablement) Bürger*innen zum Konsens durch Konfliktvermeidung.**
  * Trägt potenziell zur Entlastung der Zivilgerichte bei.**
  * Ist kostenlos.

****[Abschlussbericht „Erforschung der Ursachen des Rückgangs der Eingangszahlen bei den Zivilgerichten”](https://www.bmj.de/SharedDocs/Downloads/DE/Fachinformationen/Abschlussbericht_Eingangszahlen_Zivilgerichte.html)**`);

  return (
    <div style={containerStyle}>
      <Header />
      <SecondHeader title="About" />

      <TextComponent content={content}/>
      <TextVideoGreyComponent content={featuresContent} showButton={"false"} videoLink='https://share.synthesia.io/embeds/videos/30eb92cf-8033-4b5e-99b7-0349b2c60eb1'/>
      {/* <Iframe /> */}
      {/* <Team /> */}
      {isMobile ? <Footer2Mobile /> : <Footer2 />}
    </div>
  );
};

export default About;
