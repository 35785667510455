// firebaseConfig.ts
export const firebaseConfig = {
    apiKey: "AIzaSyDKajpYdaL395kvgImNB-T-WAuq78h377M",
    authDomain: "heylegal-59fe0.firebaseapp.com",
    databaseURL: "https://heylegal-59fe0-default-rtdb.firebaseio.com",
    projectId: "heylegal-59fe0",
    storageBucket: "heylegal-59fe0.appspot.com",
    messagingSenderId: "330048531518",
    appId: "1:330048531518:web:788b05b7a9552aba84b56f"
  };
