import React from 'react';

interface PaypalButtonProps {
  onClick: () => void;
}

const PaypalButton: React.FC<PaypalButtonProps> = ({ onClick }) => {
  const buttonStyle: React.CSSProperties = {
    backgroundColor: '#ffc439',
    border: 'none',
    color: '#003087',
    padding: '10px 30px',
    textAlign: 'center',
    textDecoration: 'none',
    display: 'inline-block',
    borderRadius: '5px',
    cursor: 'pointer',
    fontWeight: 'bold',
    fontFamily: 'Arial, sans-serif',
    marginTop: '20px'
  };

  const textStyle: React.CSSProperties = {
    marginRight: '5px',
    fontSize:'15px'
  };

  const payStyle: React.CSSProperties = {
    color: '#003087',
    fontWeight: 'bold',
    fontStyle: 'italic',
     fontSize:'20px'
  };

  const palStyle: React.CSSProperties = {
    color: '#009cde',
    fontWeight: 'bold',
    fontStyle: 'italic',
     fontSize:'20px'
  };

  return (
    <button style={buttonStyle} onClick={onClick}>
      <span style={textStyle}>Direkt</span>
      <span style={textStyle}>zu</span>
      <span style={payStyle}>Pay</span>
      <span style={palStyle}>Pal</span>
    </button>
  );
}

export default PaypalButton;
