import React from 'react';
import './css/SecondHeader.css';

interface SecondHeaderProps {
  title: string;
}
const SecondHeader : React.FC<SecondHeaderProps> = ({ title }) => {
  return (
    <div className="header">
    <span className="header-item">{title}</span>
  </div>
  );
};
export default SecondHeader;
