import React, { useEffect, useState } from 'react';
import Header from '../components/Header';
import Footer2Mobile from '../components/Footer2_Mobile';
import Footer2 from '../components/Footer2';
import useWindowSize from '../hooks/useWindowSize';
import SecondHeader from '../components/SecondHeader';
import TextComponent from '../components/Text';

const CookiePolicy: React.FC = () => {
  const containerStyle: React.CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  };

  const mainContentStyle: React.CSSProperties = {
    flex: '1',
  };

  const { width } = useWindowSize();
  const isMobile = width <= 768;

  const [content, setContent] = useState<string>(`Wir nutzen schlicht **keine Cookies**. Kein Tracking. Nichts.`);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div style={containerStyle}>
      <Header />
      <SecondHeader title="Cookie-Richtlinien" />
      <div style={mainContentStyle}>
       
      <TextComponent content={content} />
      </div>
      {isMobile ? <Footer2Mobile /> : <Footer2 />}
    </div>
  );
};

export default CookiePolicy;
