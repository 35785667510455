import React, { useEffect } from 'react';
import Newsletter from '../components/Newsletter';
import Footer2Mobile from '../components/Footer2_Mobile';
import Footer2 from '../components/Footer2';
import useWindowSize from '../hooks/useWindowSize';
import Blog from '../components/Blog';
import Header from '../components/Header';
import Articles from '../components/Articles';
import ArticleCard from '../components/ArticleCard';
import BlogHeader from '../components/BlogHeader';
import SecondHeader from '../components/SecondHeader';

const ArticlePage: React.FC = () => {
  const { width } = useWindowSize();
  const isMobile = width <= 768;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  return (
    <>
      <Header />
      <SecondHeader title={'News'}/>
      <Blog />
      {isMobile ? <Footer2Mobile /> : <Footer2 />}
    </>
  );
}

export default ArticlePage;
