import React from 'react';

const WLIcon: React.FC = () => {
  return (
    <div style={styles.container}>
      <span style={styles.text}>WL</span>
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '30px',  
    height: '30px', 
    backgroundColor: '#044557',
    color: 'white',
    borderRadius: '5px',
  },
  text: {
    fontFamily: 'Livvic, sans-serif',
    fontSize: '18px',
    fontWeight: 'bold',
    letterSpacing: '-2px',
    '@media (max-width: 768px)': { // Tablet and below
      fontSize: '16px',
    },
    '@media (max-width: 480px)': { // Mobile devices
      fontSize: '14px',
    }
  }
};

// This is to apply media queries for inline styles
const applyMediaQueries = (styles: any) => {
  const mediaQueryStyles: any = {};
  for (const key in styles) {
    if (key.startsWith('@media')) {
      const mediaQuery = key;
      const mediaQueryStyle = styles[mediaQuery];
      for (const innerKey in mediaQueryStyle) {
        mediaQueryStyles[innerKey] = {
          ...mediaQueryStyles[innerKey],
          [mediaQuery]: mediaQueryStyle[innerKey],
        };
      }
    }
  }
  return { ...styles, ...mediaQueryStyles };
};

const responsiveStyles = {
  container: applyMediaQueries(styles.container),
  text: applyMediaQueries(styles.text),
};

const WLIconResponsive: React.FC = () => {
  return (
    <div style={responsiveStyles.container}>
      <span style={responsiveStyles.text}>WL</span>
    </div>
  );
};

export default WLIconResponsive;
