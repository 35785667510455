import React from 'react';
import './css/CustomButton.css';

interface CustomButtonProps {
    onClick: () => void;
    text: string;
}

const CustomButton: React.FC<CustomButtonProps> = ({ onClick, text }) => {
    return (
        <button className="custom-button" onClick={onClick}>
            {text}
        </button>
    );
}

export default CustomButton;
